<template>
  <div class="editor">
    <a-spin :spinning="uploading" :tip="tip">
      <Toolbar
        style="border-bottom: 1px solid #ebedf0"
        :editor="editor"
        :defaultConfig="toolbarConfig"
        :mode="mode"
      />
      <Editor
        style="height: 500px; overflow-y: hidden"
        v-model="html"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onCreated="onCreated"
      />
    </a-spin>
  </div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
// import '@wangeditor/editor-for-vue/lib/css/style.css';

export default {
  name: 'WangEditor',
  components: {
    Editor,
    Toolbar,
  },
  data() {
    return {
      editor: null,
      html: '',
      toolbarConfig: {
        excludeKeys: ['uploadVideo', 'emotion', 'fullScreen'],
      },
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {
          uploadImage: {
            server: '/api/admin/upload',
            // form-data fieldName ，默认值 'wangeditor-uploaded-image'
            // fieldName: 'your-custom-name',

            // 单个文件的最大体积限制，默认为 2M
            // maxFileSize: 1 * 1024 * 1024, // 1M

            // 最多可上传几个文件，默认为 100
            maxNumberOfFiles: 1,

            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ['image/*'],

            // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
            meta: {
              type: 'post',
              // otherKey: 'yyy',
            },

            // 将 meta 拼接到 url 参数中，默认 false
            // metaWithUrl: false,

            // 自定义增加 http  header
            headers: {
              // Accept: 'text/x-json',
              Authorization: 'Bearer ' + this.$store.state.user.token,
            },

            // 跨域是否传递 cookie ，默认为 false
            // withCredentials: true,

            // 超时时间，默认为 10 秒
            // timeout: 5 * 1000, // 5 秒
            timeout: 0, // 0 代表不设置超时时间

            // 单个文件上传成功之后
            onSuccess: (file, res) => {
              // TS 语法
              // onSuccess(file, res) {          // JS 语法
              console.log(`${file.name} 上传成功`, res);
              this.$message.success('上传成功');
            },

            // 单个文件上传失败
            onFailed: (file, res) => {
              // TS 语法
              // onFailed(file, res) {           // JS 语法
              console.log(`${file.name} 上传失败`, res);
              this.$message.warn('上传失败');
            },

            // 上传错误，或者触发 timeout 超时
            onError: (file, err, res) => {
              // TS 语法
              // onError(file, err, res) {               // JS 语法
              console.log(`${file.name} 上传出错`, err, res);
              this.$message.error(`上传出错: ${err.message}`);
            },
            // 上传进度的回调函数
            // onProgress(progress: number) {  // TS 语法
            onProgress: (progress) => {
              if (progress !== 100) {
                this.uploading = true;
                this.tip = `上传中, ${progress}%`;
              } else {
                this.uploading = false;
                this.tip = '';
              }
              // JS 语法
              // progress 是 0-100 的数字
              console.log('progress', progress);
            },
          },
        },
      },
      mode: 'default', // or 'simple'
      uploading: false,
      tip: '',
    };
  },
  computed: {},
  mounted() {},
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      // console.log(this.editor.getAllMenuKeys());
    },
    setContents(content) {
      this.html = content;
    },
    getContents() {
      return this.html;
    },
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less" scoped>
.editor {
  border: 1px solid #ebedf0;
  border-radius: 4px;
  overflow: hidden;
}
</style>

<template>
  <div class="add-edit">
    <a-spin :spinning="spinLoading">
      <div class="form">
        <a-form-model
          layout="horizontal"
          ref="form"
          :model="form"
          :rules="rules"
          v-bind="{
            labelCol: { md: { span: 3 } },
            wrapperCol: { md: { span: 21 } },
          }"
          labelAlign="left"
        >
          <!-- 标题 -->
          <a-form-model-item prop="title" :wrapper-col="{ md: { span: 24 } }">
            <a-input
              class="title-input"
              v-model="form.title"
              size="large"
              :maxLength="120"
              placeholder="Please enter the article title (up to 120 characters)"
            />
          </a-form-model-item>
          <!-- 内容编辑器 -->
          <a-form-model-item prop="content" :wrapper-col="{ md: { span: 24 } }">
            <div class="editor">
              <Editor ref="editor" />
              <!-- <div id="editor"></div> -->
              <!-- <quill-editor
                ref="myQuillEditor"
                v-model="form.content"
                :options="editorOption"
              /> -->
            </div>
          </a-form-model-item>
          <!-- 文章封面 -->
          <a-form-model-item
            label="Cover"
            :colon="false"
            prop="cover"
            extra="Cover image should not exceed 500kb, and only support jpg, jpeg, and png formats."
          >
            <div v-if="imageUrl" class="cover-preview">
              <img class="preview" :src="imageUrl" alt="avatar" />
              <a-icon
                class="close-btn"
                type="close-circle"
                theme="filled"
                @click="onRemoveClick"
              />
            </div>
            <div v-else class="cover-upload">
              <a-upload
                name="avatar"
                list-type="picture-card"
                class="avatar-uploader"
                accept=".jpg,.jpeg,.png"
                :show-upload-list="false"
                :before-upload="beforeUpload"
              >
                <a-icon
                  :type="loading ? 'loading' : 'plus'"
                  style="font-size: 20px"
                />
              </a-upload>
            </div>
          </a-form-model-item>
          <!-- 文章摘要 -->
          <a-form-model-item label="Summary" :colon="false" prop="summary">
            <a-input
              type="textarea"
              v-model="form.summary"
              size="large"
              :rows="4"
              :maxLength="160"
              placeholder="Please enter the article summary (up to 160 characters)."
            />
          </a-form-model-item>
          <!-- 分类 -->
          <a-form-model-item label="Category" :colon="false" prop="category">
            <a-select
              v-model="form.category"
              size="large"
              style="width: 200px"
              placeholder="Please select the article category"
            >
              <a-select-option
                v-for="category in categoryOptions"
                :value="category._id"
                :key="category._id"
              >
                {{ category.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- 标签 -->
          <a-form-model-item label="Tags" :colon="false" prop="tags">
            <div class="tags-input">
              <div class="tags">
                <a-tag
                  closable
                  v-for="tag in form.tags"
                  :key="tag"
                  @close="onClickTagClose(tag)"
                >
                  {{ tag }}
                </a-tag>
              </div>
              <a-row type="flex" :gutter="8">
                <a-col flex="auto">
                  <a-input
                    class="tag-input"
                    size="large"
                    v-model="tag"
                    placeholder="Please enter the article tags and press Enter to create a new tag."
                    @pressEnter="onTagPressEnter"
                  />
                </a-col>
                <a-col>
                  <a-button
                    size="large"
                    type="primary"
                    @click="onTagPressEnter"
                  >
                    Add Tag
                  </a-button>
                </a-col>
              </a-row>
            </div>
          </a-form-model-item>
          <!-- 保存 -->
          <a-form-model-item
            class="operation"
            :wrapperCol="{ md: { span: 21, offset: 3 }, sm: { span: 24 } }"
          >
            <a-space>
              <a-button
                :loading="loading"
                size="large"
                type="primary"
                @click="onSubmitClick"
              >
                {{ submitButtonText }}
              </a-button>
              <!-- <a-button :loading="loading" size="large" @click="onSaveDraftClick">
              保存草稿
            </a-button> -->
            </a-space>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-spin>
  </div>
</template>

<script>
// 新增编辑
import Editor from '@/components/Editor'

export default {
  name: 'ArticleAddEdit',
  components: {
    Editor,
  },
  data() {
    return {
      quill: null,
      tag: '',
      coverFile: null,
      tempCover: null,
      loading: false,
      spinLoading: false,
      form: {
        title: '',
        cover: '',
        summary: '',
        content: '',
        category: undefined,
        tags: [],
        is_draft: false,
        is_published: true,
      },
      rules: {
        title: { required: true, message: 'Title is required' },
        cover: { required: true, message: 'Cover is required' },
        summary: { required: true, message: 'Summary is required' },
        content: { required: true, message: 'Content is required' },
        tags: { required: true, message: 'Tags is required' },
        category: { required: true, message: 'Category is required' },
      },
      categoryOptions: [],
      imageUrl: '',
      editorOption: {
        theme: 'snow',
        placeholder: 'Please enter the content of the article here',
        modules: {
          toolbar: [
            [{ font: [] }],
            [{ size: ['small', false, 'large', 'huge'] }], // 用户自定义下拉
            [{ header: 1 }, { header: 2 }], // 用户自定义按钮值
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ align: [] }],
            [{ indent: '-1' }, { indent: '+1' }], // 减少缩进/缩进
            ['bold', 'italic', 'underline', 'strike'], // 切换按钮
            [{ color: [] }, { background: [] }], // 主题默认下拉，使用主题提供的值
            ['blockquote', 'code-block'],
          ],
        },
      },
    };
  },
  computed: {
    // 提交按钮文案
    submitButtonText() {
      return this.$route.query._id ? '保存并发布' : '立即发布';
    },
  },
  async mounted() {
    // 获取文章分类列表
    await this.getCategoryList();
    // 加载详情
    if (this.$route.query._id) {
      this.getArticleDetail(this.$route.query._id);
    }
  },
  methods: {
    // onCreated(editor) {
    //   this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    // },
    onTagPressEnter() {
      if (this.tag === '') {
        this.$message.warn('Please enter a tag.');
        return;
      }
      if (this.form.tags.includes(this.tag)) {
        this.$message.warn('Tag already exists.');
        return;
      }
      if (this.form.tags.length >= 5) {
        this.$message.warn('Up to 5 tags can be created.');
        return;
      }
      this.form.tags.push(this.tag);
      this.tag = '';
    },
    onClickTagClose(tag) {
      this.form.tags = this.form.tags.filter((v) => v !== tag);
    },
    // handleChange(info) {
    //   debugger
    //   if (info.file.status === 'uploading') {
    //     this.loading = true;
    //     return;
    //   }
    //   if (info.file.status === 'done') {
    //     // 用于上传的文件变量
    //     this.coverFile = info.file.originFileObj;
    //     // 用于表单校验
    //     this.form.cover = info.file.originFileObj;
    //     // Get this url from response in real world.
    //     this.getBase64(info.file.originFileObj, (imageUrl) => {
    //       // 临时展示
    //       this.imageUrl = imageUrl;
    //       this.loading = false;
    //       // 单独校验封面
    //       this.$refs.form.validateField('cover');
    //     });
    //   }
    // },
    getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    },
    beforeUpload(file) {
      // 限制图片大小在500kb以内
      const isLt500kb = file.size / 1024 / 1024 < 0.5;
      if (!isLt500kb) {
        this.$message.error('Image must smaller than 500kb!');
        return false;
      }
      // 用于上传的文件变量
      this.coverFile = file;
      // 用于表单校验
      this.form.cover = file;
      // Get this url from response in real world.
      this.getBase64(file, (imageUrl) => {
        // 临时展示
        this.imageUrl = imageUrl;
        this.loading = false;
        // 单独校验封面
        this.$refs.form.validateField('cover');
      });
      return false;
    },
    onRemoveClick() {
      this.imageUrl = '';
      this.form.cover = '';
    },
    // 获取文章分类列表
    async getCategoryList() {
      const { err, res } = await this.$api.article.categoryList();
      if (!err) {
        this.categoryOptions = res.result.list;
      }
    },
    // 点击提交发布
    onSubmitClick() {
      this.form.content = this.$refs.editor.getContents();
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          // console.log(this.form)
          this.form.is_draft = false;
          this.form.is_published = true;
          this.saveRequest();
        }
      });
    },
    // 保存请求（创建、更新）
    async saveRequest() {
      const formData = new FormData();
      for (let key in this.form) {
        if (['cover'].includes(key)) continue;
        if (key === 'tags') {
          formData.append(key, JSON.stringify(this.form[key]));
          continue;
        }
        formData.append(key, this.form[key]);
      }
      if (this.coverFile) {
        formData.append('coverFile', this.coverFile);
      }
      this.loading = true;
      let response = {};
      if (this.$route.query._id) {
        formData.append('_id', this.$route.query._id);
        // 更新
        response = await this.$api.article.update(formData);
      } else {
        // 创建
        response = await this.$api.article.save(formData);
      }
      if (!response.err) {
        this.$message.success(response.res.msg);
        this.$router.back();
      } else {
        this.$message.error(response.res.msg);
      }
      this.loading = false;
    },
    // 获取文章详情
    async getArticleDetail(_id) {
      this.spinLoading = true;
      const { err, res } = await this.$api.article.detail({ _id });
      if (!err) {
        Object.keys(this.form).forEach((key) => {
          // if (key !== 'cover') {
          this.form[key] = res.result[key];
          this.form.category = res.result.category._id;
          // }
        });
        this.imageUrl = 'https://pinews.s3.filebase.com' + res.result.cover;
        // 渲染富文本
        this.$refs.editor.setContents(res.result.content);
      }
      this.spinLoading = false;
    },
    // 保存为草稿
    async onSaveDraftClick() {
      this.form.is_draft = true;
      this.form.is_published = false;
      this.saveRequest();
    },
  },
};
</script>

<style lang="less" scoped>
.add-edit {
  // padding: 20px;
  .form {
    ::v-deep .ant-form-item {
      margin-bottom: 16px;
      .ant-form-item-control {
        line-height: initial;
      }
      .ant-form-item-label label {
        font-size: 18px;
      }
      &.operation {
        margin: 32px 0;
      }
    }
    // border: 1px solid #ebedf0;
    // padding: 16px;
    // border-radius: 4px;
    .ant-input {
      // border: none;
      border: 1px solid #ebedf0;
      &.ant-input-lg.title-input {
        font-weight: bold;
        font-size: 20px;
        height: 56px;
        line-height: 56px;
      }
    }
    ::v-deep .editor {
      margin: 20px 0;
      .ql-toolbar.ql-snow {
        border-color: #ebedf0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      .ql-container {
        border-color: #ebedf0;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      .ql-editor {
        min-height: 400px;
      }
    }

    .tags-input {
      // display: flex;
      .tags {
        // white-space: nowrap;
        .ant-tag {
          padding: 0 8px;
          font-size: 14px;
          line-height: 37px;
          margin-bottom: 8px;
        }
      }
    }

    .cover-preview {
      display: inline-block;
      position: relative;
      img {
        border: 1px solid #ebedf0;
        border-radius: 4px;
        height: 102px;
      }
      .close-btn {
        display: none;
        position: absolute;
        font-size: 20px;
        color: rgb(255, 120, 86);
        right: -5px;
        top: -5px;
        cursor: pointer;
      }
      &:hover {
        .close-btn {
          display: block;
        }
      }
    }
    .cover-upload {
    }
  }
}
</style>
